import React from "react";

const ViewMediaIPPolicy = () => {
    return (
        <>
            <section className="py-5">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="section-title mb-5 font-bold text-white">
                                Trademarks, Copyrights and other IP at MyelinZ
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <p className="font-bold text-upper mb-4  font-22">
                                Our photos and videos
                            </p>
                            <p className="mb-3">
                                We’ve put a large number of MyelinZ photos and
                                videos on-line for your non-commercial use.
                            </p>
                            <p>
                                In order to avoid any risk of confusion, we ask
                                that the MyelinZ name, logo not be used to
                                endorse any product, entity or idea without our
                                express written permission. If you have any
                                questions concerning the usage of MyelinZ
                                materials, please submit your request to {" "}
                                <a className="text-underline" href="mailto:press@myelinz.com">press@myelinz.com</a>.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p className="font-bold text-upper mb-4  font-22">
                                Unsolicited Materials/Ideas
                            </p>
                            <p>
                                MyelinZ and its employees can’t accept or
                                consider unsolicited materials or ideas,
                                including ideas for new or improved
                                technologies, processes or materials. This
                                policy exists to avoid any misunderstandings or
                                disputes if your ideas are similar to those we
                                have developed or are developing independently.
                                Unless we have a prior agreement with you,
                                please don’t submit unsolicited materials,
                                ideas, concepts, suggestions or other works in
                                any form to us. Despite the policy, if you do
                                submit anything, and regardless of what you
                                state in your submission, you agree that there
                                is no obligation for us to review the submission
                                and that MyelinZ is free to use any such content
                                for any purposes whatsoever, including, without
                                limitation, developing and marketing products
                                and services, without any liability or payment
                                of any kind to you. We take no responsibility
                                for any unsolicited materials or ideas
                                transmitted to us.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ViewMediaIPPolicy;
