"use client";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";import { Navigation, Pagination, Autoplay } from "swiper/modules";

 

const OurPartners = () => {
    const logos = [
        {
            id: 1,
            url: "/partners/axiom.png",
            alt: "axiom",
        },
        // { id: 2, url: "/partners/boryung.png", alt: "boryung" },
        { id: 3, url: "/partners/docgo.png", alt: "docgo" },
        { id: 4, url: "/partners/goodwin.png", alt: "goodwin" },
        {
            id: 5,
            url: "/partners/Hogan_Lovells_logo.png",
            alt: "hogan-lovells",
        },
        {
            id: 6,
            url: "/partners/Imecistart-logo.png",
            alt: "imec-istart",
        },
        { id: 7, url: "/partners/ministry.png", alt: "" },
        { id: 8, url: "/partners/nyu-langone-health-logo.png", alt: "" },
        { id: 9, url: "/partners/Stanford-logo.png", alt: "" },
        { id: 10, url: "/partners/UCSD_logo.png", alt: "" },
        {
            id: 11,
            url: "/partners/axiom.png",
            alt: "axiom",
        },
        // { id: 12, url: "/partners/boryung.png", alt: "boryung" },
        { id: 13, url: "/partners/docgo.png", alt: "docgo" },
        { id: 14, url: "/partners/goodwin.png", alt: "goodwin" },
        {
            id: 15,
            url: "/partners/Hogan_Lovells_logo.png",
            alt: "hogan-lovells",
        },
        {
            id: 16,
            url: "/partners/Imecistart-logo.png",
            alt: "imec-istart",
        },
        { id: 17, url: "/partners/ministry.png", alt: "" },
        { id: 18, url: "/partners/nyu-langone-health-logo.png", alt: "" },
        { id: 19, url: "/partners/Stanford-logo.png", alt: "" },
        { id: 20, url: "/partners/UCSD_logo.png", alt: "" },
    ];

    const containerStyle = {
        width: "100vw",
        padding: "50px 0px",
        backgroundColor: "#EDF0F2",
        textAlign: "center",
        overflow: "hidden",
        position: "relative",
    };

    const headingStyle = {
        fontSize: "24px",
        fontWeight: "700",
        marginTop: "40px",
        marginBottom: "70px",
    };

    const rowWrapperStyle = {
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
        marginBottom: "30px",
    };

    const rowStyle = (direction) => ({
        display: "flex",
        gap: "80px",
        animation: `${direction} 10s linear infinite`,
        width: "100%",
    });

    const logoStyle = {
        display: "inline-block",
        maxWidth: "120px",
        maxHeight: "50px",
        opacity: 0.9,
        transition: "opacity 0.3s ease",
    };
    const logoHoverStyle = {
        opacity: 1,
    };
    return (
        <section className="bg-white py-5">
            <h2 className="section-title text-center font-bold text-primary">
                Our Partners
            </h2>
            <div className="py-5 logo-slider-container">
                <Swiper
                    slidesPerView={5}
                    spaceBetween={20}
                    centeredSlides={true}
                    loop={true}
                    speed={2500}
                    dir={"ltr"} // Set the Swiper's direction
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper mb-5"
                    autoplay={{
                        delay: 0,
                        disableOnInteraction: false,
                        reverseDirection: true,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 5,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 7,
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: 10,
                            spaceBetween: 40,
                        },
                    }}
                >
                    {/* Add your logos here */}
                    {logos.map((item, index) => (
                        <SwiperSlide key={item.id}>
                            <div
                                style={{
                                    ...logoStyle,
                                    ":hover": logoHoverStyle,
                                }}
                            >
                                <img
                                    loading="lazy"
                                    src={item.url}
                                    alt={item.alt}
                                    style={{ width: "100%", height: "auto" }}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <Swiper
                    slidesPerView={5}
                    spaceBetween={20}
                    centeredSlides={true}
                    loop={true}
                    speed={2500}
                    dir={"rtl"} // Set the Swiper's direction
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper mb-5"
                    autoplay={{
                        delay: 0,
                        disableOnInteraction: false,
                        reverseDirection: true,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 5,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 7,
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: 10,
                            spaceBetween: 40,
                        },
                    }}
                >
                    {/* Add your logos here */}
                    {logos.map((item, index) => (
                        <SwiperSlide key={item.id}>
                            <div
                                style={{
                                    ...logoStyle,
                                    ":hover": logoHoverStyle,
                                }}
                            >
                                <img
                                    loading="lazy"
                                    src={item.url}
                                    alt={item.alt}
                                    style={{ width: "100%", height: "auto" }}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
};

export default OurPartners;
