import React from "react";
import { Link } from "react-router-dom";

const TopSentence = () => {
    return (
        <section className="bg-white py-2 font-16 mb-0 ">
            <div className="container text-center">
            <p>
                    <span className="text-primary">
                    The Brain’s New Gym – Get 20% Off!  &nbsp; <br className="d-none" />
                    </span>
                    <Link
                        to="/get-your-bodymirror#choose-your-plan"
                        className="text-primary font-bold font-underline  "
                    >
                        Join the Waiting List Today.
                    </Link>
                </p>
            </div>
        </section>
    );
};

export default TopSentence;
