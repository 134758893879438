const allPodcasts = [
    {
        id: 1,
        title: `Giovanni Lauricella: Limited Partner, Managing Partner of
        Lifeblood, MedTech Recruiter, and Investor`,
        dateTime: `01 March 2024 | 75mins`,
        guestName: `Giovanni Lauricella`,
        episode: `01`,
        spotifyURL: `https://podcasters.spotify.com/pod/show/themyelinpub/embed/episodes/Giovanni-Lauricella-Limited-Partner--Managing-Partner-of-Lifeblood--MedTech-Recruiter--and-Investor-e2h33d2/a-ab2lpkr`,
        youtubeID: `DaaXGLQIWS8`,
    },
    {
        id: 2,
        title: `Christoph Guger - CEO and Founder of g. tec medical engineering`,
        dateTime: `23 May 2024 | 28mins`,
        guestName: `Christoph Guger`,
        episode: `02`,
        spotifyURL: `https://podcasters.spotify.com/pod/show/themyelinpub/episodes/Episode-02---Christoph-Guger---CEO-and-Founder-of-g--tec-medical-engineering-e2k2f3p`,
        youtubeID: `DnWuaFGkaGU`,
    },
];

export default allPodcasts;