import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SiteLayout from "../components/SiteLayout";
import ViewHome from "./ViewHome/ViewHome";
import ViewAboutUs from "./ViewAboutUs/ViewAboutUs";
import ViewNews from "./ViewNews/ViewNews";
import ViewInsights from "./ViewInsights/ViewInsights";
import ViewInsightsDetails from "./ViewInsights/[slug]/ViewInsightsDetails";
import ViewMyelinPub from "./ViewMyelinPub/ViewMyelinPub";
import ViewCareers from "./ViewCareers/ViewCareers";
import ViewNewsDetails from "./ViewNews/ViewNewsDetails";    
import ViewMediaIPPolicy from "./ViewMediaIPPolicy/ViewMediaIPPolicy";  
import ViewProducts from "./ViewProducts/ViewProducts";
import ViewContact from "./ViewContact/ViewContact";
// import ViewForBusiness from "./ViewForBusiness/ViewForBusiness";
// import ViewShop from "./ViewShop/ViewShop";
// import ViewCart from "./ViewCart/ViewCart";
// import ViewCheckout from "./ViewCheckout/ViewCheckout";
// import ViewPayment from "./ViewPayment/ViewPayment";
// import ViewPaymentSuccess from "./ViewPaymentStatus/ViewPaymentSuccsss";
// import ViewPaymentCancel from "./ViewPaymentStatus/ViewPaymentCancel";

const router = createBrowserRouter([
    {
        path: "/",
        element: <SiteLayout />, // Shared Layout
        children: [
            { index: true, element: <ViewHome /> }, // Default route
            { path: "about-us", element: <ViewAboutUs /> },
            { path: "news", element: <ViewNews /> },
            { path: "news/:slug", element: <ViewNewsDetails /> },
            { path: "insights", element: <ViewInsights/> },
            { path: "themyelinpub", element: <ViewMyelinPub/> },
            { path: "careers", element: <ViewCareers/> },
            { path: "contact", element: <ViewContact/> },
            { path: "our-products", element: <ViewProducts/> },
            { path: "myelinz-ip-media-policy", element: <ViewMediaIPPolicy/> },

            { path: "insights/:slug", element: <ViewInsightsDetails/> },
            // { path: "get-your-bodymirror", element: <ViewShop /> },
            // { path: "cart", element: <ViewCart /> },
            // { path: "checkout", element: <ViewCheckout /> },
            // { path: "payment", element: <ViewPayment /> },
            // { path: "/order/success/:orderToken", element: <ViewPaymentSuccess /> },
            // { path: "/order/cancel/:orderToken", element: <ViewPaymentCancel /> },
        ],
    },
    { path: "*", element: <ViewHome /> }, // Catch-all 404
]);

const Main = () => {
    return (
        <>
            <RouterProvider router={router} />
        </>
    );
};

export default Main;
