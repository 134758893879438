"use client";
import React, { useEffect, useState } from "react";
import TeamCard from "./_TeamCard";
import { advisorData } from "./leaderData";
import { getDocs, query, where } from "firebase/firestore";
import { advisorsCollectionRef } from "../../hooks/firebase/fire-app";

const AdvisorTeam =  () => {
    const [allAdvisors, setAllAdvisors] = useState();
    useEffect(() => {
        getDocs(
            query(advisorsCollectionRef, where("organogram", "==", "adviser"))
        ).then((snapshot) => {
            const fetchedData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setAllAdvisors(fetchedData);
        });
    }, []);

    // getDocs(advisorsCollectionRef).then((snapshot) => {
    //     const fetchedData = snapshot.docs.map((doc) => ({
    //         id: doc.id,
    //         ...doc.data(),
    //     }));
    //     setAllAdvisors(fetchedData);
    // });
    // const snapshot = await getDocs(advisorsCollectionRef);
    // const allAdvisors = snapshot.docs.map((doc) => ({
    //     id: doc.id,
    //     ...doc.data(),
    // }));
    return (
        <section className="bg-white py-5">
            <div className="container">
                <div className="leadership-section">
                    <h1 className="section-title font-bold mb-3">
                        Meet Our Advisers
                    </h1>
                    <p className="leadership-text mb-5">
                        World-class experts guiding our mission to revolutionise
                        brain health and unlock human potential through
                        groundbreaking brain-computer interfaces.
                    </p>
                </div>
                {allAdvisors && allAdvisors.length > 0 && (
                    <div className="row">
                        <div className="col-md-4">
                            <TeamCard data={allAdvisors[3]} />
                        </div>
                        <div className="col-md-4">
                            <TeamCard data={allAdvisors[4]} />
                        </div>
                        <div className="col-md-4">
                            <TeamCard data={allAdvisors[2]} />
                        </div>

                        <div className="col-md-4">
                            <TeamCard data={allAdvisors[5]} />
                        </div>
                        <div className="col-md-4">
                            <TeamCard data={allAdvisors[0]} />
                        </div>
                        <div className="col-md-4">
                            <TeamCard data={allAdvisors[1]} />
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};
export default AdvisorTeam;
