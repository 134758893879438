import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./style.scss";
// import required modules
import { Autoplay, Navigation } from "swiper/modules";

const TailwindCarousel = () => {
    const [index, setIndex] = useState(0);
    const HeliosLogo = "/testimonials/helio-logo.png";
    const LangoneLogo = "/testimonials/langone.svg";

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <>
           <section className="py-5">
           <div className="container py-5">
                <h2 className="section-title font-bold text-center mb-4 text-white">
                    Testimonials
                </h2>
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    // pagination={{
                    //     clickable: true,
                    // }}
                    navigation={true}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="row d-flex justify-content-center align-items-center ">
                            <div className="col-md-6 px-md-4">
                                <div className="testimonial">
                                    <blockquote className="font-18 font-light">
                                        "MyelinZ is rapidly advancing solutions to address the challenges of brain health and neurological functioning. Their technology holds immense potential for driving research and innovation in understanding neurological conditions. I believe their progress contributes meaningfully toward meeting critical needs and improving support for individuals living with neurological challenges."
                                    </blockquote>
                                    <p className="author mt-4 font-bold">
                                        Leigh Charvet, Director and Professor of
                                        Neurology, <br />
                                        <span>NYU Langone Health</span>
                                    </p>
                                </div>
                                {/* <p>
                                                "We believe that MyelinZ technology is of significant clinical value and is addressing unmet clinical needs with regards to the remote real-time monitoring of Parkinson's disease in the elderly. As a result, we are happy to collaborate with MyelinZ on their clinical trials, and we would be willing to consider purchasing MyelinZ proposed solution for our patients, once it is medically certified."
                                                </p> */}
                            </div>
                            <div className="col-md-3 text-center mt-4">
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href="https://nyulangone.org/"
                                >
                                    <img
                                    loading="lazy"
                                        src={LangoneLogo}
                                        alt=""
                                        style={{width: "80%", height: "auto"}}
                                        className=" mb-0 mb-md-5"
                                    />
                                </a>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-md-6 px-md-4">
                                <div className="testimonial">
                                <blockquote className="font-18 font-light">
                                "We believe that MyelinZ technology offers significant value by addressing important challenges in the brain health domain. As a result, we are pleased to collaborate with
                                        MyelinZ on their initiatives and are open to exploring innovative ways to support individuals with neurological needs." <br />
                                    </blockquote>
                                    <p className="author mt-4 font-bold">
                                        Helios Park Clinic Leipzig <br />
                                        <span>
                                            Akademisches Lehrkrankenhaus der
                                            Universität Leipzig
                                        </span>
                                    </p>
                                </div>
                                {/* <p>
                                                "We believe that MyelinZ technology is of significant clinical value and is addressing unmet clinical needs with regards to the remote real-time monitoring of Parkinson's disease in the elderly. As a result, we are happy to collaborate with MyelinZ on their clinical trials, and we would be willing to consider purchasing MyelinZ proposed solution for our patients, once it is medically certified."
                                                </p> */}
                            </div>
                            <div className="col-md-3 text-center mt-4">
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href="https://www.helios-gesundheit.de/kliniken/leipzig-park-klinikum/"
                                >
                                    <img
                                    loading="lazy"
                                        src={HeliosLogo}
                                        alt=""
                                        style={{width: "80%", height: "auto"}}

                                        className=" mb-0 mb-md-5"
                                    />
                                </a>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
           </section>
        </>
    );
};

export default TailwindCarousel;
