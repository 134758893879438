import React from "react";
import { NavbarComponent } from "./SharedComponents/NavbarComponent";
import { Outlet } from "react-router-dom";
import FooterComponent from "./SharedComponents/FooterComponent";
import ScrollToTop from "./ScrollToTop";

const SiteLayout = () => {
    return (
        <main>
            <ScrollToTop/>
            <NavbarComponent />
            <Outlet />
            <FooterComponent />
        </main>
    );
};

export default SiteLayout;
