import React from "react";

const HomeTeamCard = ({ data }) => {
    return (
        <>
            {data && (
                <div className="leadership-member">
                    <img
                    loading="lazy"
                        src={data.img}
                        alt="Team Member"
                        className=""
                        // style={{ width: "100%", height: "300" }}
                    />
                    <div>
                        <p className="font-bold mb-0 p-0 mt-3">
                            {data.designation}
                        </p>
                        <p className="mb-4 p-0">{data.name}</p>

                    </div>
                </div>
            )}
        </>
    );
};

export default HomeTeamCard;
