import React, { useEffect, useState } from "react";
import { jobPostsCollectionRef } from "../../hooks/firebase/fire-app";
import JobPostCard from "./_JobPostCard";
import { getDocs, query, where } from "firebase/firestore";

const FeaturedJobPost = () => {
    const [allJobPosts, setAllJobPosts] = useState([]);

    useEffect(() => {
        const fetchJobPosts = async () => {
            try {
                const jobPostsQuery = query(
                    jobPostsCollectionRef,
                    where("isVisible", "==", true)
                );
                const snapshot = await getDocs(jobPostsQuery);
                const jobPosts = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setAllJobPosts(jobPosts);
            } catch (error) {
                console.error("Error fetching job posts:", error);
            }
        };

        fetchJobPosts();
    }, []);

    return (
        <section className="py-5 bg-white">
            <div className="container mt-4">
                <h2 className="section-title font-bold text-center mb-23">
                    Featured Jobs
                </h2>
                {allJobPosts.length > 0 && (
                    <div className="row">
                        {allJobPosts.slice(0, 2).map((jobPost, index) => (
                            <JobPostCard
                                key={jobPost.id}
                                img={`/careers/featured-jobs/${index + 1}.png`}
                                data={jobPost}
                            />
                        ))}
                    </div>
                )}
            </div>
        </section>
    );
};

export default FeaturedJobPost;
