import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import { newsCollectionRef } from "../../hooks/firebase/fire-app";
import { getDocs, query, where } from "firebase/firestore";
import NewsCard from "./_NewsCard";
import "./news.scss";
import FeaturedNewsCard from "./_FeaturedNewsCard";
import { Link } from "react-router-dom";
import SEOHelmet from "../../components/SharedComponents/SEOHelmet";

const categories = [
    { id: 1, name: "TECHNOLOGY", value: "technology" },
    { id: 2, name: "PARTNERSHIPS", value: "partnerships" },
    { id: 3, name: "FUNDING", value: "funding" },
    { id: 4, name: "CAREERS", value: "careers" },
];

const ViewNews = () => {
    const [allNews, setAllNews] = useState(null);
    const [newsCategory, setNewsCategory] = useState(null);

    useEffect(() => {
        if (newsCategory) {
            getDocs(
                query(newsCollectionRef, where("category", "==", newsCategory))
            ).then((snapshot) => {
                const fetchedData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setAllNews(fetchedData);
            });
        } else {
            getDocs(newsCollectionRef).then((snapshot) => {
                const fetchedData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setAllNews(fetchedData);
            });
        }
    }, [newsCategory]);

    return (
        <>
            {/* <SEOHelmet title="NEWS | MyelinZ" /> */}
            <section style={{ background: "black", padding: "4rem 0rem" }}>
                <h1 className="section-title font-bold text-center mb-3">
                    The MyelinZ News
                </h1>
                <p
                    style={{
                        textAlign: "center",
                    }}
                >
                    Welcome to our NEWS page. <br />
                    Here you'll find the latest from MyelinZ.
                </p>
            </section>
            <section className="bg-white py-5">
                <Container>
                    <div className="row">
<div className="col-12">
<div className="category-button">
                            {categories.map((category) => (
                                <button
                                    key={category.id}
                                    onClick={() =>
                                        setNewsCategory(category.value)
                                    }
                                    className="btn btn-primary me-3 mb-3 mb-md-0"
                                >
                                    {category.name}
                                </button>
                            ))}
                        </div>
</div>
                      
                    </div>
                    <div className="row">
                        <div className="col-md-6 sticky-column">
                        <h1
                            className="section-title mt-5 mb-3 font-bold "
                            style={{ color: "black" }}
                        >
                            Featured News
                        </h1>
                            {allNews && allNews.length > 0 && (
                                <FeaturedNewsCard
                                    className=""
                                    col={6}
                                    data={allNews[0]}
                                />
                            )}
                        </div>
                        <div className="col-md-6">
                        <h1
                            className="section-title mt-5 d-none d-md-block mb-3 font-bold "
                            style={{ color: "black" }}
                        >
                            &nbsp;
                        </h1>
                            <div className="row">
                                {allNews &&
                                    allNews.length > 0 &&
                                    allNews.map((item) => (
                                        <NewsCard
                                            key={item.id}
                                            data={item}
                                            col="6"
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default ViewNews;
