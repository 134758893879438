import React from "react";
import "./about-us.scss";

const photos = [
    {
        src: "/awards/WhatsApp Image 2025-01-21 at 10.41.41 (2).jpeg",
        description: "MyelinZ at Y Combinator London Event",
    },
    {
        src: "/awards/WhatsApp Image 2025-01-21 at 10.41.41 (3).jpeg",
        description: "MedTech Rising Star Of The Year 2024",
    },
    {
        src: "/awards/WhatsApp Image 2025-01-21 at 10.41.41 (4).jpeg",
        description: "MedTech Rising Star Of The Year 2024",
    },
    {
        src: "/awards/WhatsApp Image 2025-01-21 at 10.41.41 (5).jpeg",
        description: "MedTech Rising Star Of The Year 2024",
    },
    {
        src: "/awards/WhatsApp Image 2025-01-21 at 10.41.43 (2).jpeg",
        description: "MyelinZ starting a collaboration with NYU Langone",
    },
    {
        src: "/awards/WhatsApp Image 2025-01-21 at 10.41.41 (1).jpeg",
        description:
            "The R-MMS product clinical trial lanuch.",
    },
    {
        src: "/awards/WhatsApp Image 2025-01-21 at 10.41.41 (6).jpeg",
        description: "The SpaceMirror product exhibition at IAC 2024.",
    },
    {
        src: "/awards/WhatsApp Image 2025-01-21 at 10.41.41 (7).jpeg",
        description: "Founders touring various embassies around the world",
    },
    {
        src: "/awards/WhatsApp Image 2025-01-21 at 10.41.43 (1).jpeg",
        description:
            "Founders touring various embassies around the world",
    },
    {
        src: "/awards/WhatsApp Image 2025-01-21 at 10.41.41.jpeg",
        description: "MyelinZ Winter Retreat 2025",
    },
];

const Awards = () => {
    return (
        <section className="awards-section">
            <div className="container">
                <h2 className="section-title text-center font-bold">
                    Our Awards
                </h2>
                <div className="photo-grid">
                    {photos.map((photo, index) => (
                        <div className="photo-item" key={index}>
                            <img loading="lazy" src={photo.src} alt={photo.description} />
                            <div className="photo-description">
                                <p>{photo.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Awards;
