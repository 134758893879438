import React from "react";
import HeroUIModal from "./HeroUIModal";

const TeamCard = ({ data, hideMeet }) => {
    function capitalizeText(text) {
        return text
            .toLowerCase() // Convert the text to lowercase first
            .split(' ')    // Split the text into words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
            .join(' ');    // Join the words back into a single string
    }
    const capitalizedName = capitalizeText(data.name);

    return (
        <>
            {data && (
                <div className="leadership-member mb-4">
                    <img
                    loading="lazy"
                        src={data.img}
                        alt="Team Member"
                        className=""
                        // style={{ width: "100%", height: "300" }}
                    />
                    <p className="font-bold mb-0 p-0 mt-3">
                        {data.designation}
                    </p>
                    <p className="mb-4 text-proper p-0">{capitalizedName}</p>
                    <div className="btn-space"></div>
                    <div className="meet-btn mt-auto">
                        {!hideMeet && <HeroUIModal data={{...data, name:capitalizedName}} />}
                    </div>
                </div>
            )}
        </>
    );
};

export default TeamCard;
