const techOverviewSteps= [
    {
        id: 1,
        text: `<p><strong>Wear the BrainMirror sensor, designed as sleek headphones and visors</strong>, to effortlessly track your brain and body activity in under a minute.</p>`,
        name: `Step 1`,
        btnText: ``,
        img: `/technology/Step1.jpeg`
    },
    {
        id: 1,
        name: `Step 2`,
        text: `<p><strong>Play MyelinZ games</strong> like mind-controlled races, brain-powered chess, muscle-driven table tennis, and art-powered mood and speech games—all designed to unlock your full potential in just a few minutes.</p>`,
        btnText: ``,
        img: `/technology/Step2.jpg`

    },
    {
        id: 1,
        name: `Step 3`,
        text: `<p><strong>See real-time results</strong> on the MyelinFace app, where AI-powered insights and scores reveal your brain and body performance like never before.</p>`,
        btnText: ``,
        img: `/technology/step-next.jpeg`

    },
    {
        id: 4,
        name: `Step 4`,
        text: `<p>Unlock your <strong>personalised game-based programme</strong> to <strong>boost your brain, mood, speech, physical strength</strong>, and more—all powered by <strong>play</strong></p>`,
        btnText: ``,
        img: `/technology/Step4.png`

    },
]

export {techOverviewSteps}