import React from "react";
import { FaSpotify, FaYoutube } from "react-icons/fa";
import YouTubeResponsiveEmbed from "../../components/YouTube/YouTubeResponsiveEmbed";
const PodcastCard = ({ data }) => {
    return (
        <div className="row align-items-center py-4 py-md-0 justify-content-center pub-card">
            <div className="col-md-5">
                <button className="btn btn-white mb-4">
                    {" "}
                    Episode: {data.episode}
                </button>{" "}
                <br />
                <h1
                    style={{
                        color: "white",
                        fontSize: "1.5rem",
                        margin: "o auto",
                    }}
                >
                    {data.title}
                </h1>{" "}
                <br />
                <p>{data.dateTime}</p>
                <br />
                <p>Guests:{data.guestName} </p>
                <div className="icons mt-4 d-flex align-items-center">
                <p className="me-4 mb-0 font-bold">Listen: </p>

                    <a target="_blank" href={data.spotifyURL} className="me-4">
                        <FaSpotify />
                    </a>
                    <a target="_blank" href={"https://www.youtube.com/watch?v="+data.youtubeID} className="me-4">
                        <FaYoutube />
                    </a>
                </div>
            </div>
            <div className="col-md-5 py-4">
                <YouTubeResponsiveEmbed
                    radius={12}
                    youtubeID={data.youtubeID}
                />
            </div>
        </div>
    );
};

export default PodcastCard;
