// const firebaseConfig = {
//     apiKey: process.env.REACT_PUBLIC_API_KEY,
//     authDomain: process.env.REACT_PUBLIC_AUTH_DOMAIN,
//     projectId: process.env.REACT_PUBLIC_PROJECT_ID,
//     storageBucket: process.env.REACT_PUBLIC_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_PUBLIC_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_PUBLIC_APP_ID,
//     measurementId: process.env.REACT_PUBLIC_MESSAGING_SENDER_ID,
// };


const firebaseConfig = {
    apiKey: "AIzaSyAnY7FO7_M5uEuak_L6oEU7TRZiwHFcIQc",
    authDomain: "ec-myelinh.firebaseapp.com",
    projectId: "ec-myelinh",
    storageBucket: "ec-myelinh.appspot.com",
    messagingSenderId: "310651316230",
    appId: "1:310651316230:web:d67da11f86d7ad9335bb87",
    measurementId: "G-9GJMP15Q9S"
  };
export default firebaseConfig;
