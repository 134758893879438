import { Link } from "react-router-dom";
import React from 'react';

const FeaturedNewsCard = ({data, col, className}) => {
    return (
        <div className={`w-100 p-4 featured-news-card ${className}`}>
            <img loading="lazy" src={"/latest-news/02.jpeg"} alt="" className="mb-3" />
            {/* <p className="text-upper">{"FEATURED"}</p> */}
            <p className=" mb-3">
            MyelinZ was honoured and thrilled to have met and presented its Neurotech to His Majesty the King of Belgium, His Royal Highness the Grand Duke of Luxembourg, along with Xavier Bettel, Minister for Foreign Affairs of Luxembourg, Hadja Lahbib, Minister for Foreign Affairs of Belgium, Yuriko Backes, Minister of Defence of Luxembourg, Lex Delles, Minister of the Economy of Luxembourg, Jean-Louis Thill, the Ambassador of Luxembourg to Belgium, and other distinguished guests and political leaders. It was an absolute privilege and pleasure to have had the opportunity to present our brain-computer interface technology, our Luxembourg-based multi-site clinical trial, as well as our business plan to make MyelinZ's neurotechnology available to over a billion patients suffering from brain disorders, helping them halt and combat their brain illness.


            </p>
            <Link to="/news/read/myelinz-presents-neurotech-to-european-leaders" className="text-link">read more</Link>
        </div>
    );
};

export default FeaturedNewsCard;