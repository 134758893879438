import { Link } from "react-router-dom";
import React from "react";

const InsightsCard = ({ data }) => {
    const truncateText = (text, wordLimit) => {
        const words = text.split(" ");
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(" ") + "...";
        }
        return text;
    };
    return (
        <>
            <Link to={"/insights/" + data.id}>
                <div className="row insight-card py-5 justify-center d-flex align-items-center">
                    <div className="col-md-5 mb-4 mb-md-0 px-4 px-md-5">
                        <h2 className="title">{data.title}</h2>
                    </div>
                    {data.seoDescription && (
                        <div className="col-md-5 px-md-5">
                            <p>{truncateText(data.seoDescription, 48)}</p>
                        </div>
                    )}
                </div>
            </Link>
        </>
    );
};

export default InsightsCard;
