import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore, collection, doc } from "firebase/firestore";

import firebaseConfig from "./config";

let fireApp;
if (!getApps().length) {
  fireApp = initializeApp(firebaseConfig);
} else {
  fireApp = getApp();
}



const fireStoreDB = getFirestore(fireApp);


export default fireApp;


export const blogsCollectionRef = collection(fireStoreDB, "blogs");
export const newsCollectionRef = collection(fireStoreDB,"news");

// export const jobPostsCollectionRe = fireStoreDB.collection("recruitmentSystem").doc("jobPosts").collection("JobPosts");

export const jobPostsCollectionRef = collection(doc(fireStoreDB,"recruitmentSystem", "jobPosts"), "JobPosts")


export const advisorsCollectionRef = collection(fireStoreDB,"myelinAdvisors")
export const myelinersCollectionRef = collection(fireStoreDB,"myeliners")


