import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import FeaturedJobPost from "./_FeaturedJobPost";
import "./careers.scss";
import NeuroRise from "./_NeuroRise";
import StudentOpportunities from "./_StudentOpportunities";
import SEOHelmet from "../../components/SharedComponents/SEOHelmet";

const ViewCareers = () => {
    return (
        <>
            {/* <SEOHelmet title="Careers | MyelinZ" /> */}
            <section className="hero-section">
                <div className="content">
                    <h1 className="hero-heading">
                        Unleash Your Potential.
                        <br />
                        Rewire Minds. Transform the Future.
                    </h1>
                    <p className="hero-description">
                        At MyelinZ, we seek exceptional talent ready to take on
                        groundbreaking projects that will revolutionize brain
                        health monitoring and rehabilitation.
                    </p>
                </div>
                <div className="overlay"></div>
            </section>
            <section className="difference-section p-md-4">
                <Container>
                    <div className="difference-content">
                        <h1 className="section-title mb-4 font-bold">
                            Make A Difference
                        </h1>
                        <p>
                            At MyelinZ, we don’t just offer jobs; we invite you
                            to join a mission — a mission to empower over a
                            billion individuals living with brain disorders,
                            including the 2.8 million people affected by
                            multiple sclerosis. Our work focuses on developing
                            home-ready brain-machine interface technology that
                            redefines how we monitor, train, and rehabilitate
                            cognitive and physical health. Beyond Earth, our
                            neurotechnology supports deep space missions,
                            enhancing cognitive performance and aiding astronaut
                            training and recovery
                        </p>
                    </div>
                    <br /> <br />
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-8">
                            <img
                                loading="lazy"
                                src="/careers/team.jpg"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </Container>
            </section>
            <section className="py-5 bg-primary">
                <div className="container">
                    <h2 className="section-title mb-4 font-bold">
                        Life at MyelinZ
                    </h2>
                    <p className="mb-3">
                        At MyelinZ, we strive to create a meritocratic
                        environment where the best ideas prevail. We believe
                        that the best results emerge from a commitment to
                        thorough and reflective exploration, empowering teams to
                        push boundaries. Whether advancing cognitive health,
                        developing brain-machine interfaces, or contributing to
                        space exploration, every team member plays a vital role
                        in shaping the future. Join us at MyelinZ and help
                        redefine what’s possible for individuals, communities,
                        and the world. Join us at MyelinZ and help redefine
                        what’s possible — for individuals, communities, and the
                        world.
                    </p>
                    <img
                        loading="lazy"
                        src="/careers/coffee-walk.jpg"
                        alt=""
                        className="img-fluid"
                    />
                </div>
            </section>
            <section className="py-5 bg-primary">
                <div className="container text-right">
                    <h2 className="section-title mb-4 font-bold">
                        A Meritocratic Place
                    </h2>
                    <p className="mb-3">
                        Our five cultural mottos and team principles are
                        encapsulated in what we affectionately term the
                        "Myelinner Dress Code." It's not a literal dress code;
                        instead, it outlines expectations for those who join our
                        journey and come along for the ride. Get to know them by
                        reading this blog
                    </p>
                    <img
                        loading="lazy"
                        src="/careers/career-hero.jpg"
                        alt=""
                        className="img-fluid"
                    />
                </div>
            </section>
            <NeuroRise />

            <FeaturedJobPost />
            <StudentOpportunities />
        </>
    );
};

export default ViewCareers;
