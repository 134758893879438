import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { officeMapData } from "./data-contact-map";
import "./contact.scss";
import SEOHelmet from "../../components/SharedComponents/SEOHelmet";
const ViewContact = () => {
    return (
        <main className="bg-white py-5">
            {/* <SEOHelmet title="Contact Us | MyelinZ" /> */}
            <div className="container">
                <h1 className="section-title mb-4 font-bold">Get in Touch</h1>
                <div className="row mb-5">
                    {" "}
                    <div className="col-md-6">
                        <div className="mb-4">
                            <p className="font-bold">
                                BodyMirror product enquiries:{" "}
                            </p>
                            <p>
                                <a
                                    href="mailto:bodymirror@myelinz.com"
                                    className="text-link"
                                    target="_self"
                                >
                                    bodymirror@myelinz.com
                                </a>
                            </p>
                        </div>
                        <div className="mb-4">
                            <p className="font-bold">For press enquiries, </p>
                            <p>
                                <a
                                    href="mailto:press@myelinz.com"
                                    className="text-link"
                                >
                                    press@myelinz.com
                                </a>
                            </p>
                        </div>
                        <div className="mb-4">
                            <p className="font-bold">
                                For career enquiries and opportunities at
                                MyelinZ:{" "}
                            </p>
                            <p>
                                <a
                                    href="mailto:careers@myelinz.com"
                                    className="text-link"
                                >
                                    careers@myelinz.com
                                </a>
                            </p>
                        </div>
                        <div className="mb-4">
                            <p className="font-bold">For general enquiries:</p>
                            <p>
                                <a
                                    href="mailto:info@myelinz.com"
                                    className="text-link"
                                >
                                    info@myelinz.com
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-4">
                            <p className="font-bold">
                                For investment enquiries:{" "}
                            </p>
                            <p>
                                <a
                                    href="mailto:investment@myelinz.com"
                                    className="text-link"
                                >
                                    investment@myelinz.com
                                </a>
                            </p>
                        </div>
                        <div className="mb-4">
                            <p className="font-bold">For R-MMS enquiries:</p>
                            <p>
                                <a
                                    href="mailto:r-mms@myelinz.com"
                                    className="text-link"
                                >
                                    r-mms@myelinz.com
                                </a>
                            </p>
                        </div>
                        <div className="mb-4">
                            <p className="font-bold">
                                For SpaceMirror enquiries:
                            </p>
                            <p>
                                <a
                                    href="mailto:myelinspace@myelinz.com"
                                    className="text-link"
                                >
                                    myelinspace@myelinz.com
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <p className="font-bold text-center font-26">
                    Contact the MyelinZ support team: <u>+ 44 2039678373</u>
                </p>
            </div>
            <div className="container">
                <h1
                    className="section-title font-bold"
                    style={{ marginTop: "60px", marginBottom: "30px" }}
                >
                    Worldwide Offices
                </h1>
                <div className="row">
                    <LocationMapCard data={officeMapData[0]} />
                    <LocationMapCard data={officeMapData[1]} />
                    <LocationMapCard data={officeMapData[2]} />
                    <LocationMapCard data={officeMapData[3]} />
                </div>
            </div>
        </main>
    );
};

export default ViewContact;

const LocationMapCard = ({ data }) => {
    return (
        <div className="col-md-6 mb-4">
            <div className="contact-map-card">
                <Card.Img
                    variant="top"
                    src={data.img}
                    style={{ borderRadius: "10px" }}
                />
                <Card.Body>
                    <Card.Text>
                        <h5 style={{ fontWeight: "700", marginTop: "20px" }}>
                            {data.name}
                        </h5>
                        <p
                            style={{
                                color: "#393C41",
                                fontSize: "1rem",
                                fontWeight: "100",
                                marginTop: "20px",
                            }}
                        >
                            {data.city}
                        </p>
                        <p
                            style={{
                                color: "#393C41",
                                fontSize: "1rem",
                                fontWeight: "100",
                                marginBottom: "20px",
                            }}
                        >
                            {data.address}
                        </p>
                    </Card.Text>
                    <a
                        target="_blank"
                        href={data.mapURL}
                        style={{
                            color: "#393C41",
                            borderBottom: "1px solid black",
                        }}
                    >
                        View on map
                    </a>
                </Card.Body>
            </div>
        </div>
    );
};
