"use client";
import React, { useEffect, useState } from "react";
import { techOverviewSteps } from "./tech-overview";

const TechOverview = () => {
    const [activeTechCard, setActiveTechCard] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            if (activeTechCard < 3) {
                setActiveTechCard(activeTechCard + 1);
            } else {
                setActiveTechCard(0);
            }
        }, 1500);
    }, [activeTechCard]);
    return (
        <>
            <section id="products-overview" className="py-5 bg-primary">
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-title font-bold mt-5 mb-4">
                            Explore Our Neurotechnology: <br />
                            Your Brain and Body in Your Hands—Anytime, Anywhere
                        </h2>
                        <div className="row d-flex justify-content-center mb-5">
                            <div className="col-md-9">
                                <p className="font-26">
                                    Experience the fusion of{" "}
                                    <strong>neuroscience</strong> and{" "}
                                    <strong>innovation</strong> with our
                                    neurotechnology —{" "}
                                    <strong>
                                        the future of brain and body wellness
                                    </strong>{" "}
                                    begins with <strong>MyelinZ</strong>.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex  mt-4">
                        <div className="col-md-2"></div>

                        <TechOverviewCard
                            className={activeTechCard === 0 && "active"}
                            data={techOverviewSteps[0]}
                        />

                        <TechOverviewCard
                            className={activeTechCard === 1 && "active"}
                            data={techOverviewSteps[1]}
                        />
                        <div className="col-md-2"></div>
                        <div className="col-md-2"></div>

                        <TechOverviewCard
                            className={activeTechCard === 2 && "active"}
                            data={techOverviewSteps[2]}
                        />

                        <TechOverviewCard
                            className={activeTechCard === 3 && "active"}
                            data={techOverviewSteps[3]}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default TechOverview;

const TechOverviewCard = ({ data, className }) => {
    return (
        <div className="col-md-4 mb-4">
            <div className={"tech-overview-card " + className}>
                <div className="text-box p-4 pb-0">
                    <p className="font-bold">{data.name}</p>
                    <div dangerouslySetInnerHTML={{ __html: data.text }}></div>

                    {/* <a
                        className="btn mt-3 btn-primary"
                        href={data.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {data.btnText}
                    </a> */}
                </div>
                <div className="img-section"></div>
                <img
                    loading="lazy"
                    className="p-4"
                    src={data.img}
                    alt={data.name}
                />
            </div>
        </div>
    );
};
