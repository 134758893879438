export const officeMapData =[
    {
        id: 1,
        name: `MyelinZ headquarters`,
        name: `MyelinZ United Kingdom - Headquarters`,
        address: `SPACES Deansgate 125 Deansgate Manchester M3 2BY, United
                    Kingdom`,
        mapURL: `https://maps.app.goo.gl/Qki3Ni1FZG4wN95z6`,
        img: `/contact/01.png`
    },
    {
        id: 1,
        name: `MyelinZ United States - Engineering & Business`,
        city: `Boston, United States`,
        address: `265 Franklin St. Ste 1702, Boston Ma, 02110, United States`,
        mapURL: `https://maps.app.goo.gl/Hnp73G9qhHA8EKsQ6`,
        img: `/contact/02.png`

    },
    {
        id: 1,
        name: `MyelinZ Luxembourg - European Headquarters`,
        city: `Esch-Belval Esch-sur-Alzette, Luxembourg`,
        address: ` 9 Av. des Hauts-Fourneaux, 4362 Esch-Belval
                    Esch-sur-Alzette, Luxembourg`,
        mapURL: `https://maps.app.goo.gl/fiv8wV9weqXViGV29`,
        img: `/contact/03.png`

    },
    {
        id: 1,
        name: `MyelinZ Belgium - R&D, Sales, and Medical Research`,
        city: `Brussels, Belgium`,
        address: `Place des tulipes 15 1070 Anderlecht, Brussels, Belgium`,
        mapURL: `https://maps.app.goo.gl/q4oy6NMSTVJdQrDA8`,
        img: `/contact/04.png`

    },
]