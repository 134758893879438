import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TemplateSingle from './TemplateSingle';
import { doc, getDoc } from 'firebase/firestore';
import { blogsCollectionRef } from '../../../hooks/firebase/fire-app';

const ViewInsightsDetails = () => {
    const { slug } = useParams(); // Get the slug from the URL
    const [insightDetails, setInsightDetails] = useState(null);

    useEffect(() => {
        const fetchInsightDetails = async () => {
            try {
                const docRef = doc(blogsCollectionRef, slug); // Use slug as the document ID
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setInsightDetails({ id: docSnap.id, ...docSnap.data() });
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching document:', error);
            }
        };

        if (slug) {
            fetchInsightDetails();
        }
    }, [slug]); // Add slug as a dependency

    return (
        <>
            {
                insightDetails && <TemplateSingle blog={insightDetails} />
            }
        </>
    );
};

export default ViewInsightsDetails;