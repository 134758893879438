import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
} from "@heroui/react";

const EventPopup = () => {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [popupShown, setPopupShown] = useState(false);
    useEffect(() => {
        if (!popupShown) {
            setTimeout(() => {
                onOpen();
            }, 5000);
        }
    }, []);
    const [scrollBehavior, setScrollBehavior] = React.useState("inside");

    const regLink = `https://www.eventbrite.co.uk/e/bodymirror-pre-launch-tickets-1244646915429?aff=oddtdtcreator&utm-source=cp&utm-campaign=social&utm-content=attendeeshare&utm-medium=discovery&utm-term=listing`;
    return (
        <>
            <div className="container">
                <Modal
                    isDismissable={false}
                    isKeyboardDismissDisabled={true}
                    isOpen={isOpen}
                    onOpenChange={onOpenChange}
                    size="4xl"
                    scrollBehavior={scrollBehavior}
                >
                    <ModalContent>
                        {(onClose) => (
                            <>
                                <ModalHeader className="flex text-primary flex-col gap-1"></ModalHeader>
                                <ModalBody>
                                    <div className="row d-flex align-items-center text-primary">
                                        <div className="col-md-7">
                                            <p className="font-bold font-22 mb-4">
                                                Join the BodyMirror Pre-Launch
                                                Event!
                                            </p>
                                            <p>
                                                The World's First Virtual Gym
                                                For The Brain - Upgrade Your
                                                Brain Today
                                            </p>

                                            <p>
                                                🧠 Discover the
                                                first-of-its-kind
                                                neurotechnology designed to
                                                track and enhance your body and
                                                brain performance in real-time!
                                            </p>
                                            <br />
                                            <p>
                                                📅 BodyMirror Pre-Launch Event –
                                                Highlights <br />
                                                ✅ Live Demo of Brain & Body
                                                Performance Tracking <br />✅
                                                Networking & Collaboration Zone{" "}
                                                <br />✅ Early Access & Business
                                                Partnerships <br />
                                            </p>
                                            <br />
                                            <p>
                                                📅{" "}
                                                <span className="font-bold">
                                                    Date
                                                </span>
                                                : 26.02.2025 <br />
                                                📍{" "}
                                                <span className="font-bold">
                                                    Location
                                                </span>
                                                : Manchester, United Kingdom
                                            </p>
                                            <p>
                                                🎟️{" "}
                                                <span className="font-bold">
                                                    Limited slots{" "}
                                                </span>{" "}
                                                available! Register now to
                                                secure your spot.
                                            </p>
                                        </div>
                                        <div className="col-md-5 text-center">
                                            <p className="font-bold">
                                                Scan the QR code below to
                                                register instantly!
                                            </p>
                                            <div className="px-5">
                                                <img
                                                    src="/events/bodymirror-launch.svg"
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </div>
                                            <a
                                                href={regLink}
                                                className="btn btn-primary"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {" "}
                                                Register now!!
                                            </a>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onPress={onClose}>
                                        Close
                                    </Button>
                                </ModalFooter>
                            </>
                        )}
                    </ModalContent>
                </Modal>
            </div>
        </>
    );
};

export default EventPopup;
