import React, { useEffect, useState } from "react";
import {
    Navbar,
    NavbarBrand,
    NavbarMenuToggle,
    NavbarMenuItem,
    NavbarMenu,
    NavbarContent,
    NavbarItem,
    Button,
} from "@nextui-org/react";
import FredocloudLogoWhite from "./FredocloudLogoWhite";
import { MdArrowDropDownCircle } from "react-icons/md";
import "./nav.scss";
import MegaMenu from "./MegaMenu";
import { NavItem } from "react-bootstrap";
import navMenuItems from "../../data/nav-data";
import TopSentence from "./TopSentence";
import { Link, useLocation } from "react-router-dom";
export const NavbarComponent = () => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [showMegaMenu, setShowMegaMenu] = useState(false);
    const location = useLocation(); // Detects route changes

    useEffect(()=>{
        setIsMenuOpen(false)
    },[location])
    return (
        <>
            <TopSentence />
            <Navbar
                onMenuOpenChange={setIsMenuOpen}
                isBordered
                shouldHideOnScroll
                isMenuOpen={isMenuOpen}
                maxWidth="full"
                className="position-relative"
                // className="custom-nav"
            >
                {/* toggle */}
                <NavbarContent>
                    <NavbarMenuToggle
                        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                        className="sm:hidden"
                    />
                    <NavbarBrand as={Link} to={"/"}>
                        <FredocloudLogoWhite />
                    </NavbarBrand>
                </NavbarContent>

                {/* items */}

                <NavbarContent
                    className="d-none d-md-flex gap-10"
                    justify="center"
                >
                    {/* <NavbarContent
                    className="hidden sm:flex gap-10"
                    justify="center"
                > */}
                    <NavbarItem>
                        <button
                            className="text-md products-nav font-medium text-white h-100"
                            // onClick={() => setShowMegaMenu(!showMegaMenu)}
                        >
                            <div className="d-flex align-items-center h-100">
                                Products{" "}
                                <MdArrowDropDownCircle className="ms-1" />
                            </div>
                            <MegaMenu />
                        </button>
                    </NavbarItem>

                    {navMenuItems.map((item) => (
                        <NavbarItem key={item.id}>
                            <Link
                                className="text-md font-medium text-white "
                                to={item.href}
                            >
                                {item.name}
                            </Link>
                        </NavbarItem>
                    ))}
                </NavbarContent>

                {/* ending */}
                <NavbarContent justify="end">
                    <NavbarItem>
                        <a
                            to="https://bodymirror.myelinz.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn-white btn-small"
                        >
                            Upgrade Your Brain
                        </a>
                    </NavbarItem>
                </NavbarContent>

                <NavbarMenu className="pt-5">
                    <div className="py-4 pt-4"></div>
                    <NavItem>
                        <Link
                            className="text-md font-medium text-white "
                            to="/our-products"
                        >
                            Products 
                        </Link>
                    </NavItem>
                    {navMenuItems.map((item, index) => (
                        <NavbarItem key={item.id}>
                            <Link
                                className="text-md font-medium text-white "
                                to={item.href}
                            >
                                {item.name}
                            </Link>
                        </NavbarItem>
                    ))}
                </NavbarMenu>
            </Navbar>
        </>
    );
};
