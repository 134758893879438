import React from "react";
import "./about-us.scss";
import LeadershipTeam from "./_LeadershipTeam";
import AdvisorTeam from "./_AdvisorTeam";
import Awards from "./_Awards";
import TechnicalTeam from "./_TechnicalTeam";
import TimelineComponent from "./_TimelineComponent";
import SEOHelmet from "../../components/SharedComponents/SEOHelmet";
// import AlternateTime from "./AlternateTime";
import YearToYearProgress from "./YearToYearProgress";
const ViewAboutUs = () => {
    return (
        <>
            {/* <SEOHelmet title="About Us | MyelinZ" /> */}
            <section className="py-5 home-hero">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-12 text-center">
                            <h1 className="hero-title text-center font-bold py-5">
                                Born from a Personal Journey. <br />
                                Driven by Innovation.
                            </h1>
                            <p className="text-center font-26 px-md-5">
                                <strong>MyelinZ</strong> began with a mission to
                                transform lives through{" "}
                                <strong>neurotechnology</strong>. Today, it’s a
                                movement redefining brain and body performance,
                                one breakthrough at a time.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mission-section py-5">
                <div className="container text-center">
                    <h2 className="section-title font-bold">
                        Our Mission: <br /> Redefining Brain Health
                    </h2>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10">
                            <p className="font-26 mb-4">
                                Inspired by his mother’s resilience in battling
                                a neurological condition, Dr Zied Tayeb
                                co-founded MyelinZ to reimagine brain health.
                                Our mission is to develop transformative
                                neurotechnology that empowers people to overcome
                                cognitive, mental, and physical challenges.
                            </p>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-5">
                            <img
                                loading="lazy"
                                src="/about-us/zied_tayeb_myelinz.jpeg"
                                alt="Mission"
                                className="img-fluid rounded"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="vision-section py-5 pb-5">
                <div className="container text-center">
                    <h2 className="section-title font-bold">
                        Vision: <br />
                        Pioneering a New Era of Brain Health
                    </h2>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-7">
                            <p className=" font-26">
                                We are shaping the future of personalised brain
                                health, empowering millions to restore and
                                enhance their cognitive and physical well-being.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="video-section py-5 pb-5">
                <div className="container">
                    <iframe
                        src="https://www.youtube.com/embed/U-XPTaV83gE?autoplay=1&loop=1&mute=1&rel=0"
                        className="video-full-width"
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                    ></iframe>
                </div>
            </section>

            <LeadershipTeam />
            <AdvisorTeam />
            {/* <TechnicalTeam /> */}
            <Awards />
            {/* <AlternateTime/> */}
            <TimelineComponent />
            {/* <YearToYearProgress /> */}
        </>
    );
};

export default ViewAboutUs;
