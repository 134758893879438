"use client";
import React, { useEffect, useState } from "react";

const ProductsOverView = () => {
    const [activeCard, setActiveCard] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            if (activeCard < 2) {
                setActiveCard(activeCard + 1);
            } else {
                setActiveCard(0);
            }
        }, 1500);
    }, [activeCard]);


    // useEffect(() => {

    //     if (window.location.hash === "#our-products") {
            
    //         const element = document.getElementById("our-products");
    //         if (element) {
    //             element.scrollIntoView({ behavior: "smooth", block: "start" });
    //         }
    //     }
    // }, []);

    const productData = [
        {
            id: 1,
            name: "BodyMirror",
            img: `/products/bodymirror.png`,
            text: `The Brain’s New Gym – Unlock your free trial today!`,
            btnText: `Unlock now!`,
            url: `https://bodymirror.myelinz.com`,
        },
        {
            id: 2,
            name: "R-MMS",
            img: `/products/r-mms.png`,
            text: `Redefining & Revolutionising the Future of Multiple Sclerosis Care.`,
            btnText: `Learn more`,
            url: `https://myelinh.myelinz.com   `,
        },
        {
            id: 3,
            name: "MyelinSpace",
            img: `/products/spacemirror.png`,
            text: `Neurotechnology for a safe and human-led space exploration.`,
            btnText: `Learn more`,
            url: `h ttps://myelinspace.myelinz.com`,
        },
    ];
    return (
        <>
            
            <section id="our-products" className="py-5 bg-primary">
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-title font-bold mb-4">
                        Our Products
                                                </h2>
                        <div className="row d-flex justify-content-center mb-5">
                            <div className="col-md-7">
                                <p>
                                    From BodyMirror to R-MMS, we combine
                                    advanced AI, neuroscience, and immersive
                                    games to track, enhance, and transform your
                                    brain and body health.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <ProductOverviewCard
                            className={activeCard === 0 && "active"}
                            data={productData[0]}
                        />
                        <ProductOverviewCard
                            className={activeCard === 1 && "active"}
                            data={productData[1]}
                        />
                        <ProductOverviewCard
                            className={activeCard === 2 && "active"}
                            data={productData[2]}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProductsOverView;

const ProductOverviewCard = ({ data, className }) => {
    return (
        <div className="col-md-4 mb-4">
            <div className={"products-overview-card " + className}>
                <img
                    src={data.img}
                    alt=""
                    className="mb-2 bg-white"
                />
                <div className="text-box p-4">

                    <p className="font-bold">{data.name}</p>
                    <p>{data.text}</p>

                    <a
                        className="btn mt-3 btn-primary"
                        href={data.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {data.btnText}
                    </a>
                </div>
            </div>
        </div>
    );
};