import React from "react";
import "./home.scss";

const HomeHeroComponent = () => {
  return (
    <>
      <section className="py-5 home-hero">
        <div className="container py-5 ">
          <div className="row d-flex justify-content-center mt-5 py-5">
            <div className="col-md-10 text-center my-5">
              <div className="py-md-4">&nbsp;</div>
              <h1 className="hero-title text-center mt-5 mt-md-0 font-bold py-5">
              Upgrade Your Mind. <br /> Unleash Your Potential.
              </h1>
              <p className="text-center font-26 px-md-5">
              <strong>
              A First-of-its-Kind Virtual Gym for the Brain.</strong> <br />
              Wear BrainMirror Headphones or Visors, play engaging games, and unlock AI insights to <b>transform</b> your <b>brain</b> and <b>body health</b>.
             
              </p>
              <div className="d-flex mt-4 mb-5 justify-content-center">
                <a href="#products-overview" className="btn btn-white me-4 me-md-5">Learn More</a>
                <a href="http://bodymirror.myelinz.com" target="_blank" rel="noopener noreferrer" className="btn btn-white ">Upgrade Your Brain</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeHeroComponent;

