import React from 'react';
import HomeHeroComponent from './HomeHeroComponent';
import HomeCatelogue from './_HomeCatelogue';
import CallToInnovation from './_CallToInnovation';
import TechOverview from './_TechOverview';
import ProductsOverView from './_ProductsOverView';
import HomeLeadershipTeam from './_HomeLeadershipTeam';
import OurPartners from './_OurPartners';
import HomeNewsSection from './_HomeNewsSection';
import TailwindCarousel from './WhatClientsSay/TailwindCarousel';
import HomeAwardsSection from './_HomeAwardsSection';
import HomeCareer from './_HomeCareer';
import CookieConsent from './CookieConsent';
import SEOHelmet from '../../components/SharedComponents/SEOHelmet';

const ViewHome = () => {
    return (
        <main className="home-page">
      {/* <TopSentence /> */}
      <SEOHelmet/>
      <HomeHeroComponent />

      <HomeCatelogue/>
      <CallToInnovation />

      <TechOverview/>

      <ProductsOverView />

      <TailwindCarousel/>

      <HomeLeadershipTeam />
      <OurPartners />
      <HomeNewsSection />
      <HomeAwardsSection/>
      <HomeCareer />

      <CookieConsent />
    </main>
    );
};

export default ViewHome;