import React from "react";
import "./home-awards.scss";
import { Link } from "react-router-dom";

const HomeAwardsSection = () => {
    const awardCollection = [
        {
            src: "/awards/WhatsApp Image 2025-01-21 at 10.41.41 (3).jpeg",
            description: "MedTech Rising Star Of The Year 2024",
        },
        {
            src: "/awards/WhatsApp Image 2025-01-21 at 10.41.41 (4).jpeg",
            description: "MedTech Rising Star Of The Year 2024",
        },
        {
            src: "/awards/WhatsApp Image 2025-01-21 at 10.41.41 (7).jpeg",
            description: "Founders touring various embassies around the world",
        },
    ];
    return (
        <section className="py-5 awards-section">
            <div className="container">
                <div className="row">

                    <div className="col-md-12 text-center mb-4">
                        <h2 className="section-title  font-bold mb-3">
                            Awards
                        </h2>
                        <Link to="/about-us" prefetch={true} className="btn btn-primary">
                            Learn More
                        </Link>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-10">
                        <div className="photo-grid">
                            {awardCollection.map((photo, index) => (
                                <div className="photo-item" key={index}>
                                    <img
                                    loading="lazy"
                                        src={photo.src}
                                        alt={photo.description}
                                    />
                                    <div className="photo-description">
                                        <p>{photo.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeAwardsSection;
