import React from "react";

const NeuroRise = () => {
    return (
        <>
            <section className="py-5 bg-primary">
                <div className="container text-center">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-9">
                            <h2 className="section-title mb-4 font-bold">
                                NeuroRise
                            </h2>
                            <p className="mb-3">
                                It doesn’t matter where you’re from, what you’ve
                                studied, or what field you’ve worked in—if
                                you’ve accomplished extraordinary things, join
                                us at MyelinZ to transform the future of brain
                                health and neurotechnology.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 p-4">
                            <img
                                loading="lazy"
                                src="/careers/neurorise02.jpg"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-4 p-4">
                            <img
                                loading="lazy"
                                src="/careers/swajan-myelinz.png"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-4 p-4">
                            <img
                                loading="lazy"
                                src="/careers/alice-myelinz.png"
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default NeuroRise;
