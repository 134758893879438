import React from "react";

const JobPostCard = ({ data,img }) => {
  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };
  return (
    <>
      <div className="col-md-4 mb-4 p-4">
        <div className="job-post-card">
          <img loading="lazy" src={img} alt="" className="w-100" />

          <div className="text-box">
            <p className="font-bold">{data.title}</p>
            <p className="font-16 mb-3">{truncateText(data.details, 22)}</p>
          </div>
          <div className="text-left">
            <a
              href={data.applyLink}
              className="btn btn-primary btn-sm"
              target="_blank"
              rel="noopener noreferrer"
            >
              APPLY NOW
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobPostCard;
