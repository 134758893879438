import React from "react";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
} from "@heroui/react";
import { FaLinkedinIn } from "react-icons/fa";

const HeroUIModal = ({ data }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [size, setSize] = React.useState("full");

    const handleOpen = (size) => {
        setSize(size);
        onOpen();
    };

    return (
        <>
            <button
                className="btn btn-primary"
                onClick={() => handleOpen(size)}
            >
                Meet
            </button>
            <div className="flex flex-wrap gap-3"></div>
            <Modal isOpen={isOpen} size={size} onClose={onClose}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalBody>
                                <div className="container-fluid d-flex align-items-center h-100p text-primary">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-md-12">
                                            <div className=" text-primary">
                                                <p className="flex flex-col font-32 font-bold text-primary gap-1">
                                                    {data.name}
                                                </p>
                                                <p className="font-bold">
                                                    {data.designation}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <img
                                                loading="lazy"
                                                src={data.img}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="col-md-9">
                                            <div
                                                className="details-info font-14 p-0 p-md-4"
                                                dangerouslySetInnerHTML={{
                                                    __html: data.publicDescription,
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button
                                    className="btn btn-danger"
                                    onClick={onClose}
                                >
                                    Close
                                </button>
                                {data.organogram === "technical" ? (
                                    ""
                                ) : (
                                    <a
                                        href={data.socialLinkedIn}
                                        className="btn btn-primary"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div className="d-flex align-items-center">
                                            <span className="me-3">
                                                Connect
                                            </span>{" "}
                                            <FaLinkedinIn />
                                        </div>
                                    </a>
                                )}
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default HeroUIModal;
