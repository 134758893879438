import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import TeamCard from "./_TeamCard";
import { leaderData } from "./leaderData";

const LeadershipTeam = () => {
    return (
        <>
            <section className="bg-white py-5">
                <div className="container">
                    <div className="leadership-section">
                        <h1 className="section-title font-bold mb-3">
                            Meet Our Leadership Team
                        </h1>
                        <p className="leadership-text mb-5">
                            Globally dispersed, united by one mission—to develop
                            neurotechnology that redefines brain health and
                            unlocks human potential.
                        </p>
                    </div>
                    {leaderData && leaderData.length > 0 && (
                        <div className="row ">
                            <div className="col-md-4">
                                <TeamCard data={leaderData[0]} />
                            </div>
                            <div className="col-md-4">
                                <TeamCard data={leaderData[1]} />
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

export default LeadershipTeam;
