import React from "react";

const StudentOpportunities = () => {
    return (
        <section className="student-opportunities-section">
            <div className="overlay"></div>
            <div className="container py-5 content">
                <div className="row d-flex align-items-center">
                    <div className="col-md-1">
                    </div>
                    <div className="col-md-11 text-right">
                        <h2 className="section-title mb-4 font-bold">
                            Student Opportunities
                        </h2>
                        <p className="mb-4">
                        <b>MyelinZ NeuroLaunch</b> is a 12-week programme where students and early-career professionals tackle groundbreaking neurotech challenges using MyelinZ's advanced BCI technology. Gain hands-on experience, build skills, and launch your future in neurotech.
                        </p>
                        <a href={`https://forms.office.com/pages/responsepage.aspx?id=lDKJytWHzEiumDs6Y3eofZhZkca7MgNDq-prZRSJ6IRUOTA0SFA3R0o3VldPNTVUMFhaQzdOR0Q4UC4u&origin=lprLink&route=shorturl`} target="_blank" className="btn-white btn">Apply now</a>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default StudentOpportunities;
