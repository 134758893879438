import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import "./myelin-pub.scss";
import PodcastCard from "./_PodcastCard";
import allPodcasts from "./podcast-data";
import SEOHelmet from "../../components/SharedComponents/SEOHelmet";

const ViewMyelinPub = () => {
    const myelinPubData = [
        {
            episode: "1",
            title: "Join the Myelin-H Team: Unveiling Our Recruitment Process — Tips To Become a Myelinner & Banana Skins to Avoid",
            date: "23 May,2024",
            duration: "28 minutes",
            guest: "Christoph Guger",
            image: "/pub/thumb.png",
        },
        {
            episode: "2",
            title: "Join the Myelin-H Team: Unveiling Our Recruitment Process — Tips To Become a Myelinner & Banana Skins to Avoid",
            date: "23 May,2024",
            duration: "28 minutes",
            guest: "Christoph Guger",
            image: "/pub/thumb.png",
        },
    ];
    return (
        <>
            {/* <SEOHelmet title="MyelinPub | MyelinZ" /> */}
            <Container>
                {" "}
                <h1
                    className="section-title font-bold text-center mb-5 "
                    style={{ paddingTop: "40px" }}
                >
                    The MyelinPub
                </h1>
                <section>
                    <Row className="py-4 d-flex align-items-center">
                        <Col sm={12} md={5}>
                            <div className="px-4">
                                <img
                                    loading="lazy"
                                    src="/pub/pub-banner.png"
                                    alt=""
                                    className="img-fluid px-5"
                                />
                            </div>
                        </Col>
                        <Col sm={12} md={7}>
                            <div>
                                <p>
                                    The MyelinPub, hosted by Dr Zied Tayeb and
                                    Samaher Garbaya, is a monthly podcast
                                    exploring brain health, NeuroTech, and
                                    neuroscience through enlightening
                                    conversations with experts—from investors
                                    and policymakers to neuroscientists and
                                    patient advocates. Grab your tea, tune in,
                                    and join us as we unravel the marvels and
                                    mysteries of NeuroTech and brain health!
                                </p>

                                <br />
                                <p className="font-bold">
                                    Host: Dr Zied Tayeb,{" "}
                                    <span>Founder and CEO @MyelinZ</span>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>
            <section className="container-fluid py-5">
                <Row>
                    {allPodcasts.map((item, index) => (
                        <PodcastCard data={item} key={item.episode} />
                    ))}
                </Row>
            </section>
        </>
    );
};

export default ViewMyelinPub;
