"use client";

import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import styles from "./cookieConsent.module.scss";
import { Link } from "react-router-dom";

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = Cookies.get("cookieConsentNew");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookieConsentNew", "accepted", { expires: 365 });
    setIsVisible(false);
  };

  const handleReject = () => {
    Cookies.set("cookieConsentNewcookieConsentNew", "rejected", { expires: 365 });
    setIsVisible(false);
  };

  const handleSettings = () => {
    alert("Redirect to cookie settings or open settings modal.");
  };

  return isVisible ? (
    <div className={`${styles.banner} `}>
      <div className={`${styles.container} ${styles.bannerContent}`}>
        <p className={styles.text}>
          By clicking "Accept All Cookies," you agree to the storing of cookies
          on your device to enhance site navigation, analyze site usage, and
          assist in our marketing efforts.{" "}
          <Link to="/privacy-policy" className={styles.link}>
            Privacy Policy
          </Link>
        </p>
        <div className={styles.buttonContainer}>
          <button onClick={handleSettings} className={styles.buttonSecondary}>
            Cookie Settings
          </button>
          <button onClick={handleReject} className={styles.buttonSecondary}>
            Reject All
          </button>
          <button onClick={handleAccept} className={styles.buttonPrimary}>
            Accept All Cookies
          </button>
        </div>
        <button
          onClick={() => setIsVisible(false)}
          className={styles.closeButton}
        >
          ✕
        </button>
      </div>
    </div>
  ) : null;
};

export default CookieConsent;
