"use client";
import React from "react";

const HomeCatelogue = () => {
    return (
        <>
            <section id="products-overview" className="pt-5 bg-primary">
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-title font-bold mt-5 mb-4">
                            Discover MyelinZ: <br /> Upgrade Your Brain & Body
                            Today
                        </h2>
                        <div className="row d-flex justify-content-center mb-5">
                            <div className="col-md-10">
                                <p className="mb-4 font-26">
                                    One App. A Few Minutes. Total Brain & Body
                                    Upgrade. From cognition to mood, speech, and
                                    strength—everything in one powerful app.
                                </p>
                                <a
                                    href="https://myelinz.aflip.in/33b54bc1f5.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-primary"
                                >
                                    View Our Catalogue
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <iframe
                                    style={{ height: "293px", width: "800px" }}

                    // style="border: 1px solid lightgray; width: 800px; height: 293px;"
                    src="https://myelinz.aflip.in/33b54bc1f5.html"
                ></iframe> */}
                {/* <iframe allowfullscreen="allowfullscreen" scrolling="no" class="fp-iframe" style="border: 1px solid lightgray; width: 800px; height: 293px;" src="https://myelinz.aflip.in/33b54bc1f5.html#page/2"></iframe> */}
                <iframe
                    className="bg-primary pb-4"
                    // allowFullScreen="allowFullScreen"
                    // className="fp-iframe"
                    src="https://myelinz.aflip.in/33b54bc1f5.html#page/2"
                    style={{ height: "600px", width: "100%" }}
                ></iframe>
            </section>
        </>
    );
};

export default HomeCatelogue;
