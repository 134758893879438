import { Link } from "react-router-dom";import React from "react";

const HomeNewsSection = () => {
  const featuredNews = {
    img: `https://firebasestorage.googleapis.com/v0/b/ec-myelinh.appspot.com/o/blogs%2Fundefined%2Ffrom-brain-drain-to-cosmic-gain-myelin-hs-mission-to-save-astronauts-minds?alt=media&token=de1c06c7-509d-400d-97ce-d1d77d6dd3aa`,
    category: `TECHNOLOGY`,
    title: `From Brain Drain to Cosmic Gain: Myelin-H’s Mission to Save Astronauts’ Minds`,
  };
  return (
    <section className="bg-white py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mb-4">
            <h2 className="section-title  font-bold mb-3">
              Featured News
            </h2>
            <Link to="/news" className="btn btn-primary">
              More News
            </Link>

          </div>
          <div className="col-md-4 "></div>
        </div>
        <div className="row d-flex align-items-center">
          <FeaturedNewsCard />
        </div>
      </div>
    </section>
  );
};

export default HomeNewsSection;

const NewsCard = ({ data, col }) => {
  return (
    <div className={`col-md-${col} p-4 featured-news-card mb-3`}>
      <img loading="lazy" src={data.img} alt="" className="mb-3" />
      <p className="text-upper">{"Technology"}</p>
      <p className="font-bold mb-3">{data.title}</p>
    </div>
  );
};

const FeaturedNewsCard = ({ data, col }) => {
  return (
    <div className="row">
      <div className={`col-md-6 pe-5 featured-news-card`}>
        <img
        loading="lazy"
          src={"/latest-news/02.jpeg"}
          alt=""
          className="mb-3"
        />
      </div>
      <div className="col-md-6 featured-news-card">
        <p className="font-bold text-upper mb-2">
          FEATURED
        </p>
  
        <p className="text-upper font-36 mb-3 font-bold">
        MyelinZ Presents Neurotech to European Leaders

        </p>
        <p className=" mb-3">
        MyelinZ was honoured to present its groundbreaking neurotechnology to His Majesty the King of Belgium, His Royal Highness the Grand Duke of Luxembourg, and other distinguished leaders, including Xavier Bettel, Luxembourg's Prime Minister, and key ministers from Belgium and Luxembourg. This moment underscores our commitment to revolutionising brain health and empowering lives globally.
        </p>
      </div>
    </div>
  );
};
