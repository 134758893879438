export const leaderData = [
    {
        id: 1,
        name: `Dr Zied Tayeb`,
        designation: `Founder & CEO`,
        img: "/home/zied_tayeb_founder.png",
        publicDescription: `<p>I’m Zied, Founder and CEO of MyelinZ, where we are redefining the possibilities of brain-computer interface (BCI) technology to track and enhance brain and body performance. Our mission is to empower individuals with innovative neurotechnology that unlocks their mental, physical, and cognitive potential, starting with those living with neurological challenges. My passion for this work stems from a deeply personal story, driving my unwavering commitment to making a meaningful difference in people’s lives. With a PhD in neuroengineering and a background in computer science, I have dedicated my career to exploring the intersection of technology and neuroscience. I am honoured to have received global recognition for my contributions, including being selected by NASA as one of the top 10 innovators in 2019, nominated for the International Brain-Computer Interface Award in 2020, and recognised as a leader in neurotechnology by the Royal Academy of Engineering in 2022. 

</p>`,
        socialLinkedIn: `https://www.linkedin.com/in/zied-tayeb/`,
    },
    {
        id: 2,
        name: `Samaher Garbaya`,
        designation: `Co-Founder & COO`,
        img: "/home/samaher_garbaya.jpeg",
        publicDescription: `<p>I am Samaher, the co-founder and COO of MyelinZ, where we are redefining the possibilities of brain-computer interface (BCI) technology to track and enhance brain and body performance. The company stems from the personal story of its founder, which drives my commitment to making a difference in people's lives. </p>
      <p>
      I hold a first master's degree in machine learning and applied mathematics and a second master's degree in space technologies from the University of Luxembourg. 
      </p>
<p>
My work has received international recognition, including the nomination by the Luxembourg Space Agency and Space Generation Advisory Council as one of the top 5 space talents in 2019, and the Care In Space. 


</p>      
      `,
        socialLinkedIn: `https://www.linkedin.com/in/samaher-garbaya/`,
    },
    
];
