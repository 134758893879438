import { Helmet } from "react-helmet";

const SEOHelmet = ({ title, description, keywords }) => {
    const base_title = `MyelinZ - The Brain’s New Gym | AI-Powered Neurotechnology`
    const base_description = `Unlock your brain’s full potential with MyelinZ, the world’s first virtual gym for the brain. Wear BrainMirror Headphones or Visors, play immersive AI-powered neurogames, and get real-time insights to enhance cognition, mood, speech, and strength. 🚀 Join the waiting list today and get 20% off!`;
    const base_keywords = `brain gym, neurotechnology, AI for brain health, cognitive enhancement, brain training, neuroscience gaming, MyelinZ, BrainMirror headphones, neurotech, brain-body optimization, AI-powered health, digital brain training, mind-controlled games, neuroscience fitness, brainwave tracking, neuro-enhancement, cognitive performance boost, neurological wellness, brain health solutions`;
    return (
        <Helmet>
            <title>{title?title: base_title}</title>
            <meta name="description" content={description?description: base_description} />
            <meta name="keywords" content={keywords?keywords: base_keywords} />
            <meta property="og:title" content={title?title: base_title} />
            <meta property="og:description" content={description?description: base_description} />
            <meta property="og:type" content="website" />
        </Helmet>
    );
};

export default SEOHelmet;
