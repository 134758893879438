import { Link } from "react-router-dom";
import React from "react";
import { leaderData } from "../ViewAboutUs/leaderData";
import HomeTeamCard from "../ViewAboutUs/_HomeTeamCard";

const HomeLeadershipTeam = () => {
  return (
    <section className="bg-white py-5">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-md-5">
            <h2 className="section-title font-bold mb-3">Leadership Team</h2>
            <p className="leadership-text mb-3">
              Pioneers, scientists, engineers, and visionaries united by a
              mission to redefine brain health and revolutionise lives through
              life-changing neurotechnology.
            </p>
            <Link to="/about-us" className="btn btn-primary">
              About us
            </Link>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-6 p-4">
                <HomeTeamCard hideMeet={true} data={leaderData[0]} />
              </div>
              <div className="col-md-6 p-4">
                <HomeTeamCard hideMeet={true} data={leaderData[1]} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeLeadershipTeam;
