import { blogsCollectionRef } from "../../hooks/firebase/fire-app";
import { getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import InsightsCard from "./_InsightsCard";
import "./insights.scss";

// import InsightsCard from "./_InsightsCard";

const ViewInsights = () => {
    const [allInsights, setAllInsights] = useState(null);
    const snapshot = getDocs(blogsCollectionRef);
    useEffect(() => {
        snapshot.then((snapshot) => {
            const fetchedData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setAllInsights(fetchedData);
        });
    }, []);
    return (
        <section className="pb  " style={{ background: "white" }}>
            <h1
                className="section-title font-bold text-center mb-5 "
                style={{ color: "black", paddingTop: "40px" }}
            >
                INSIGHTS
            </h1>

            <div className="container-fluid">
                {allInsights &&
                    allInsights.length > 0 &&
                    allInsights.map((item) => (
                        <InsightsCard data={item} key={item.id} />
                    ))}
            </div>

            {/* <div className="text-center mb-5 mt-5">
                <button className="btn-primary btn mb-5">Load more</button>
            </div> */}
        </section>
    );
};

export default ViewInsights;
