import React from "react";
import { Link } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa";
import {
    FaInstagram,
    FaLinkedinIn,
    FaFacebookF,
    FaYoutube,
} from "react-icons/fa";
import "./footer.scss";
import EventPopup from "../EventPopups/EventPopup";

const FooterComponent = () => {
    const linkInsta = `https://www.instagram.com/fredocloud/`;
    const linkLinkedIn = `https://www.linkedin.com/company/myelinh/`;
    const linkYouTube = `https://www.youtube.com/@MyelinZ`;

    return (
        <>
            <footer className="py-5">
                <div className="container">
                    <img
                    loading="lazy"
                        src="/myelinz-logo-white.png"
                        alt=""
                        className="footer-logo mb-4"
                    />

                    <div className="row">
                        <div className="col-md-3 pe-md-4">
                            <p className="font-base">
                                We combine advanced AI, neuroscience, and
                                immersive games to track, enhance, and transform
                                your brain and body health.
                            </p>
                        </div>
                        <div className="col-md-3 ps-md-5">
                            <p className="font-bold mb-3">Links</p>
                            <Link
                                className=" text-secondary mb-3 d-inline-block"
                                to="/about-us"
                            >
                                About us
                            </Link>{" "}
                            <br />
                            <Link
                                className=" text-secondary mb-3 d-inline-block"
                                to="/news"
                            >
                                News
                            </Link>{" "}
                            <br />
                            <Link
                                className=" text-secondary mb-3 d-inline-block"
                                to="/insights"
                            >
                                Insights
                            </Link>{" "}
                            <br />
                            <Link
                                className=" text-secondary mb-3 d-inline-block"
                                to="/themyelinpub"
                            >
                                The Myelin Pub
                            </Link>{" "}
                            <br />
                            <Link
                                className=" text-secondary mb-3 d-inline-block"
                                to="/careers"
                            >
                                Careers
                            </Link>{" "}
                            <br />
                            <Link
                                className=" text-secondary mb-3 d-inline-block"
                                to="/myelinz-ip-media-policy"
                            >
                                MyelinZ IP & Media Policy
                            </Link><br />
                            <a 
                                                            className=" text-secondary mb-3 d-inline-block"

                            href="https://myelinz.aflip.in/33b54bc1f5.html" target="_blank" rel="noopener noreferrer">

                            View Our Catalogue
                            </a>
                            <br />
                        </div>
                        <div className="col-md-3">
                            <p className="font-bold mb-3">Locations</p>
                            <p className="text-secondary mb-2">
                                <span className="font-bold text-white">
                                    {" "}
                                    United Kingdom
                                </span>
                                <br />
                                SPACES Deansgate 125 Deansgate Manchester M3
                                2BY, United Kingdom
                            </p>
                            <p className="text-secondary mb-2">
                                <span className="font-bold text-white">
                                    United States
                                </span>
                                <br />
                                265 Franklin St. Ste 1702, Boston Ma, 02110,
                                United States
                            </p>
                            <p className="text-secondary">
                                <span className="font-bold text-white">
                                    Luxembourg
                                </span>
                                <br />
                                Av. des Hauts-Fourneaux, 4362 Esch-Belval
                                Esch-sur-Alzette, Luxembourg{" "}
                            </p>
                            <p className="text-secondary">
                                <span className="font-bold text-white">
                                    {" "}
                                    Belgium
                                </span>
                                <br />
                                Brussels, Belgium Place des tulipes 15 1070
                                Anderlecht, Brussels, Belgium
                            </p>
                        </div>
                        <div className="col-md-3">
                            <p className="font-bold mb-3">Social Media</p>
                            <div className="d-flex">
                                <a
                                    href={linkLinkedIn}
                                    className="d-inline-block me-4 font-36"
                                >
                                    <FaLinkedinIn />
                                </a>
                                {/* <a href={linkInsta} className="d-inline-block me-4 font-36">
                  <FaInstagram />
                </a> */}
                                {/* <a href={linkFB} className="d-inline-block me-4 font-36">
                  <FaFacebookF />
                </a> */}
                                <a
                                    href={linkYouTube}
                                    className="d-inline-block me-4 font-36"
                                >
                                    <FaYoutube />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <section className="py-3">
                <div className="container">
                    <p className="text-white text-style-italic">
                      Disclaimer:  MyelinZ products, including BodyMirror technology, are intended for research applications and personal use only. Our products are not classified as Medical Devices under EU Directive 93/42/EEC and are not designed or intended for the diagnosis or treatment of any disease.
                    </p>
                </div>
            </section>
            <div className="py-4 border-top">
                <div className="container d-flex align-items-center justify-content-between">
                    <p className="mb-0">
                        <Link                             to="https://myelinz.com/media/privacy_policy_myelinz.pdf"
                        >Terms of use</Link> |{" "}
                        <a
                            href="https://myelinz.com/media/privacy_policy_myelinz.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </a>
                    </p>
                    <p className="mb-0">©2025 MyelinZ, All rights reserved.</p>
                </div>
            </div>
            <EventPopup/>
        </>
    );
};

export default FooterComponent;
