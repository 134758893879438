import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { newsCollectionRef } from '../../hooks/firebase/fire-app';
import TemplateSingle from '../ViewInsights/[slug]/TemplateSingle';

export async function generateStaticParams() {
    const snapshot = await getDocs(newsCollectionRef);
    const paths = snapshot.docs.map(doc => ({
        slug: doc.id, // Assuming 'slug' is the document ID
    }));

    return paths; // Returns all the slugs to pre-render pages
}

const ViewNewsDetails = () => {
    const { slug } = useParams(); // Get the slug from the URL
    const [insightDetails, setInsightDetails] = useState(null);

    useEffect(() => {
        const fetchNewsDetails = async () => {
            try {
                const docRef = doc(newsCollectionRef, slug); // Use slug as the document ID
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setInsightDetails({ id: docSnap.id, ...docSnap.data() });
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching document:', error);
            }
        };

        if (slug) {
            fetchNewsDetails();
        }
    }, [slug]); // Add slug as a dependency

    return (
        <>
            {
                insightDetails && <TemplateSingle blog={insightDetails} />
            }
        </>
    );
};

export default ViewNewsDetails;