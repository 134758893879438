import React from 'react';
import ProductsOverView from '../ViewHome/_ProductsOverView';

const ViewProducts = () => {
    return (
        <>
            <ProductsOverView/>
        </>
    );
};

export default ViewProducts;