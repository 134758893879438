import { Link } from "react-router-dom";
import React from "react";

const NewsCard = ({ data, col }) => {
    return (
        <div className={`col-md-${col} p-4  mb-3`}>
            <div className="news-card">
                <img loading="lazy" src={data.featuredImage} alt="" className="mb-3" />
                <p className="text-upper">{data.category}</p>
                <p className="font-bold mb-4">{data.title}</p>

                <Link to={"/news/"+data.slug} className="text-link read-more">read more</Link>
            </div>
        </div>
    );
};

export default NewsCard;
