// components/Timeline.js
import "./TimelineComponent.scss";

import { Link } from "react-router-dom";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

const TimelineComponent = () => {
    const timelineData = [
        {
            year: "2021",
            description:
                "Myelin-H is founded to develop medical devices for multiple sclerosis (MS).",
        },
        {
            year: "Q1-2024",
            description:
                "Myelin-H successfully closes its pre-seed funding round.",
        },
        {
            year: "Q2-2024",
            description:
                "Clinical trial for R-MMS (a medical device for MS) is approved and funded.",
        },
        {
            year: "December 2024",
            description:
                "Myelin-H rebrands to MyelinZ, launching a portfolio of three innovative products:\n" +
                "○ R-MMS: A medical device for MS developed by Myelin-H.\n" +
                "○ SpaceMirror: An astronaut brain health app developed by MyelinSpace.\n" +
                "○ BodyMirror: The largest brain health and wellbeing product, described as 'the new gym for the brain.'",
        },
        {
            year: "December 2024",
            description: "Five US patents and trademarks are filed.",
        },
        {
            year: "January 2025",
            description:
                "MyelinZ signs three collaboration agreements with top global medical institutions to pilot BodyMirror.",
        },
        {
            year: "March 2025",
            description: "BodyMirror is launched to the public.",
        },
    ];

    return (
        <>
            <section className="py-5">
                <div className="container-fluid py-4">
                    <h2 className="section-title font-bold">
                        The MyelinZ Voyage <br /> So Far...
                    </h2>
                </div>
                <div className="steps">
                    <ul className="steps-list">
                        {timelineData.map((item, index) => (
                            <StepComponent
                                key={item.year + index}
                                data={item}
                            />
                        ))}
                    </ul>
                </div>
            </section>
        </>
    );
};

export default TimelineComponent;

const StepComponent = ({ data }) => {
    return (
        <li className="steps-list-item completed text-white">
           <div className="card f-card p-2">
           <b className="font-16 font-bold">{data.year}</b> <br />{" "}
            <i className="font-14">{data.description}</i>
            <span></span>
           </div>
            <span></span>
        </li>
    );
};
