import { Link } from "react-router-dom";import React from "react";

const HomeCareer = () => {
  return (
    <section className="py-5 bg-primary">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-md-9 text-center mb-5">
            <h2 className="section-title mb-4 font-bold">
              MyelinZ Careers: <br /> Solving Complexity, Changing Lives
            </h2>
            <p>
            At MyelinZ, we seek passionate innovators ready to revolutionise brain health and empower millions worldwide.
            </p>
          </div>
          <div className="col-md-9">
            <img loading="lazy" src="/careers/zied-samaher.jpeg" alt="" className="img-fluid" />
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-md-3 d-flex justify-content-center">
            <Link
              to="/careers"
              className="btn btn-white px-5 py-3 font-weight-bold"
              style={{
                fontSize: "20px",
              }}
            >
              Join Our Mission
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeCareer;
