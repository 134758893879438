const navMenuItems = [

    {
        id: 2,
        name: "About Us",
        href: "/about-us",
    },

    // {
    //     id: 3,
    //     name: "Products",
    //     href: "/products",
    // },
    {
        id: 4,
        name: "News",
        href: "/news",
    },

    {
        id: 5,
        name: "TheMyelinBlog",
        href: "/insights",
    },

    {
        id: 6,
        name: "TheMyelinPub",
        href: "/themyelinpub",
    },
    {
        id: 7,
        name: "Careers",
        href: "/careers",
    },
    {
        id: 8,
        name: "Contact",
        href: "/contact",
    },
];

export default navMenuItems;
