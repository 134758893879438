import React from "react";
import { FaEye } from "react-icons/fa";

const TemplateSingle = ({ blog }) => {
    // useEffect(() => {
    //     if (type === "blog") {
    //         blogsCollectionRef.doc(blog.id).update({
    //             totalViews: firebase.firestore.FieldValue.increment(1)
    //         })
    //     }

    // }, [updateView])

    return (
        <>
            {blog && (
                <section className="bg-light">
                    <div className="container pt-3">
                        <div className="row justify-content-center">
                            <div className="col-md-9 bg-white p-0 border-right">
                                <img
                                loading="lazy"
                                    src={blog.featuredImage}
                                    alt=""
                                    className="img-fluid"
                                />
                                <h1 className="font-36 font-bold text-center">
                                    {blog.title}
                                </h1>
                                {/* {blog.tags && (
                                    <span className="post-category text-primary">
                                        <div className="mt-1 text-center">
                                            <span className="text-italic font-14">Keywords: </span>
                                            {blog &&
                                                blog.tags &&
                                                blog.tags.map((item) => (
                                                    <span className="btn btn-xs mr-1 btn-primary">
                                                        {item}
                                                    </span>
                                                ))}
                                        </div>
                                    </span>
                                )} */}
                                {/* <p className="text-center mt-4"><i>{blog.category}</i> </p> */}
                                <div className="px-4 pb-4 pt-0">
                                    {/* <div className="pb-2">
                                <p>Written by:<a href="http://fb.com/smswajan" target="_blank" rel="noopener noreferrer">Team Myelin-H</a> </p>
                                <p className="text-primary">{timeStampToDate(blog.createdAt)} &nbsp;&nbsp; | &nbsp; &nbsp; <FaEye /> &nbsp; {blog.totalViews} </p>
                            </div> */}
                                    <article>
                                        <div className="blog-content ck-content">
                                            <div
                                                className=""
                                                dangerouslySetInnerHTML={{
                                                    __html: blog.content,
                                                }}
                                            ></div>
                                            {/* {blog.ytVideo && <div className="row mb-5">
                                        <div className="col-md-8 offset-md-2">
                                            <YouTubeEmbed youtubeId={blog.ytVideo} />
                                        </div>
                                    </div>} */}
                                        </div>
                                    </article>
                                </div>
                            </div>
                            {/* <div className="col-md-3">
                            {categories && categories.map(item => <Link href={"/category/" + item.replace(" ", "-")}><span className="mb-3 mx-2 btn btn-primary">{item}</span></Link>)}
                        </div> */}
                        </div>
                        {/* <FeaturedBlogs textSmall={true}/> */}
                    </div>
                    {/* <section className="author-block py-5 container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <h6 className="text-primary"> <span>AUTHOR</span></h6>
                            <div className="row d-flex align-items-center">
                                <div className="col-md-4 p-3" >
                                    <Link to="/">
                                        <img className="img-fluid" src="https://swajan.io/static/swajan-ea762356f9f5fbe751d9ab5503e74e8f.jpg" alt="" />
                                    </Link>
                                </div>
                                <div className="col-md-8">
                                    <Link to="/" className="text-dark font-weight-bold">Shahjahan Md Swajan</Link>
                                    <p>With over 500,000 monthly readers, Adam Enfroy’s mission is to teach the next generation of online entrepreneurs how to scale their influence at startup speed. You can follow him on Facebook, Twitter, and Instagram.
</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                </section>
            )}
        </>
    );
};

export default TemplateSingle;
