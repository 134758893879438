import React from 'react';
import Main from './views/Main';

const App = () => {
    return (
        <>
            <Main/>
        </>
    );
};

export default App;