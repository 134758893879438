"use client";
import React, { useEffect, useState } from "react";
import "./mega-menu.scss";
const MegaMenu = () => {
    const [activeCard, setActiveCard] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            if (activeCard < 2) {
                setActiveCard(activeCard + 1);
            } else {
                setActiveCard(0);
            }
        }, 1500);
    }, [activeCard]);
    const productData = [
        {
            id: 1,
            name: "BodyMirror",
            img: `/products/bodymirror.png`,
            text: `Brain Health & Wellness Product`,
            btnText: `Unlock now!`,
            url: `https://bodymirror.myelinz.com`,
        },
        {
            id: 2,
            name: "R-MMS",
            img: `/products/r-mms.png`,
            text: `Medical Device for Multiple Sclerosis`,
            btnText: `Learn more`,
            url: `https://myelinh.myelinz.com   `,
        },
        {
            id: 3,
            name: "MyelinSpace",
            img: `/products/spacemirror.png`,
            text: `Care In Space Product`,
            btnText: `Learn more`,
            url: `https://myelinspace.myelinz.com`,
        },
    ];
    return (
        <div
            id="mega-menu-full-cta-dropdown"
            className="mt-1 z-100 bg-white border-gray-200 shadow-sm border-y dark:bg-gray-800 dark:border-gray-600"
        >
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-8">
                        <div className="row">
                            {productData.map((item) => (
                                <ProductOverviewCard
                                    data={item}
                                    key={item.id}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="col-md-4 p-4">
                        <div className="mega-menu-text d-flex align-items-center p-4">
                            <div>
                                <h4 className="font-bold">MyelinZ</h4>
                                <p>
                                    We combine advanced AI, neuroscience, and
                                    immersive games to track, enhance, and
                                    transform your brain and body health.
                                </p>
                                <button className="btn btn-primary mt-4">
                                    Order now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MegaMenu;

const ProductOverviewCard = ({ data, className }) => {
    return (
        <div className="col-md-4">
            <a
                href={data.url}
                target="_blank"
                rel="noopener noreferrer"
            >
                <div className="mega-item-card text-center">
                    <img src={data.img} alt="" className="img-fluid bg-" />
                    <p className="font-bold text-center">{data.text}</p>
                </div>
            </a>
        </div>
    );
};
