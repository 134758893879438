"use client";
import React, { useEffect, useRef } from "react";
import "./home.scss";

const CallToInnovation = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }, []);

    const promoVideo = `https://firebasestorage.googleapis.com/v0/b/ec-myelinh.appspot.com/o/myelinz.com%2FMyelinZ%20-%20Neurotechnology%20In%20Action.mp4?alt=media&token=43914267-cdd4-480a-9b47-adf9631ebda5`;

    return (
        <section className="pb-5">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-title font-bold mb-4">
                        Our Neurotechnology in Action
                    </h2>
                    <div className="row d-flex justify-content-center mb-4">
                        <div className="col-md-7">
                            <p className="font-26">
                                Redefining Brain and Body Health With Gaming,
                                Neurotechnology, and AI to Unlock Human
                                Potential.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mt-4">
                        {/* <video
                            ref={videoRef}
                            style={{ width: "100%", height: "auto" }}
                            autoPlay
                            muted
                            loop
                        >
                            <source src={promoVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video> */}
                        <iframe
                        
                            src="https://www.youtube.com/embed/QtLhyjkqa14?autoplay=1&loop=1&mute=1&rel=0"
                            className="video-full-width"
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CallToInnovation;
